import React, { Suspense, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Steps, Button } from "rsuite";
import { Icon } from "@rsuite/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAddressCard,
    faClipboardList,
    faGraduationCap,
    faBriefcase,
    faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../utilities/Loading";
import PropTypes from "prop-types";
import "./CreateProfile.css";
const CreateManualProfileDetailsSession = React.lazy(() =>
    import("./CreateManualProfileDetailsSession")
);
const CreateManualProfileSummarySession = React.lazy(() =>
    import("./CreateManualProfileSummary")
);
const CreateManualProfileEducation = React.lazy(() =>
    import("./CreateManualProfileEducation")
);
const CreateManualProfileExperience = React.lazy(() =>
    import("./CreateManualProfileExperience")
);
const CreateManualProfileOtherInfo = React.lazy(() =>
    import("./CreateManualProfileOtherInfo")
);

const CreateManualProfileMain = ({
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    errors,
    watch,
    clearErrors,
    required,
    setError,
    step,
    setStep,
    page,
    resumeId,
    campaignID,
    isDirty, 
    isValid,
    from,
    createProfileModalSubmit
}) => {
    // const [step, setStep] = useState(0);
    const [targetPage, setTargetPage] = useState("Profile");
    const [skip,setSkip] = useState(false);
    const onChange = (nextStep) => {
        if (nextStep < 0) {
            setStep(0)
        } else {
            if (nextStep > 3) {
                setStep(3)
            } else {
                setStep(nextStep)
            }
        }
        // setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
    };
    const onPrevious = () => onChange(step - 1);
    const onNext = () => onChange(step + 1);
    const handleProfileDetailsDetails = (target) => {
        if (target == "Profile") {
            setStep(0);
            setTargetPage("Profile");
        } else if (target == "Experience" ) {
            setStep(1);
            setTargetPage("Experience");
            // setTargetPage("Summary");
        } else if (target == "Education"  ) {
            setStep(2);
            setTargetPage("Education");
        } else if (target == "Summary"  ) {
            setStep(3);
            setTargetPage("Summary");
            // setTargetPage("Experience");
        } 
        // else if (target == "otherDetails"  ) {
        //     setStep(4);
        //     setTargetPage("otherDetails");
        // }
    };
    //&& watch()?.createAge == true && watch()?.createLegal == true 
    useEffect(() => {
        if (step == 0) {
            setTargetPage("Profile");
        } else if (step == 1 ) { //
            setTargetPage("Experience");
            // setTargetPage("Summary");
        } else if (step == 2 ) {
            setTargetPage("Education");
        } else if (step == 3 ) {
            // setTargetPage("Experience");
            setTargetPage("Summary");
            setSkip(true)
        }
        //  else if (step == 4 ) {
        //     setTargetPage("otherDetails");
        // }
    }, [step]);

    const [isSmallScreen, setIsSmallScreen] = useState(
        typeof window !== 'undefined' ? window.innerWidth < 768 : false
      );
    
      useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(typeof window !== 'undefined' ? window.innerWidth < 768 : false);
        };
    
        if (typeof window !== 'undefined') {
          window.addEventListener('resize', handleResize);
    
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }
      }, []);

    return (
        <>
            { page !== "login" && page !== "guest" && page !== "referalresume" && 
            <div className="row ">
                <div className="col-sm-12  d-sm-block d-none">
                    <Steps current={step} className={page !== "login" && page !=="guest" && page !== "referalresume" ? "d-flex justify-content-center" : ""}>
                    { <>
                        <Steps.Item
                            className="cursor-pointer"
                            onClick={handleSubmit(() => handleProfileDetailsDetails("Profile"))}
                            title={<Trans i18nKey="profile" defaults="Profile" />}
                            icon={
                                <Icon style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon icon={faAddressCard} />
                                </Icon>
                            }
                        />
                        <Steps.Item
                            className="cursor-pointer"
                            onClick={handleSubmit(() => handleProfileDetailsDetails("Experience"))}
                            title={<Trans i18nKey="experience" defaults="Experience" />}
                            icon={
                                <Icon style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon icon={faBriefcase} />
                                </Icon>
                            }
                        />
                        <Steps.Item
                            className="cursor-pointer"
                            onClick={handleSubmit(() => handleProfileDetailsDetails("Education"))}
                            title={<Trans i18nKey="education" defaults="Education" />}
                            icon={
                                <Icon style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon icon={faGraduationCap} />
                                </Icon>
                            }
                        />
                        <Steps.Item
                            className="cursor-pointer"
                            onClick={handleSubmit(() => handleProfileDetailsDetails("Summary"))}
                            title={<Trans i18nKey="summary" defaults="Summary" />}
                            icon={
                                <Icon style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon icon={faClipboardList} />
                                </Icon>
                            }
                        />
                        
                        
                        {/* <Steps.Item
                            className="cursor-pointer"
                            onClick={handleSubmit(() => handleProfileDetailsDetails("otherDetails"))}
                            title={<Trans i18nKey="otherDetails" defaults="Other Details" />}
                            icon={
                                <Icon style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon icon={faStickyNote} />
                                </Icon>
                            }
                        />  */}
                        </> 
                        // : 
                        // <>
                        //     <Steps.Item
                        //     className="cursor-pointer d-flex justify-content-start"
                        //     onClick={handleSubmit(() => handleProfileDetailsDetails("Profile"))}
                        //     title={<Trans i18nKey="profile" defaults="Profile" />}
                        //     icon={
                        //         <Icon style={{ fontSize: 20 }}>
                        //             <FontAwesomeIcon icon={faAddressCard} />
                        //         </Icon>
                        //     }
                        // />
                        // </>
                        }
                    </Steps>
                </div>
                 {/* web responsive  rsuite steps displayed to vertical  */}
                <div className="col-sm-12  d-sm-none d-block d-flex justify-content-center">
                    <Steps current={step} className={!page=="guest" && page !== "referalresume" ? "d-flex justify-content-center" : ""} vertical small>
                    { <>
                        <Steps.Item
                            className="cursor-pointer"
                            onClick={handleSubmit(() => handleProfileDetailsDetails("Profile"))}
                            title={<Trans i18nKey="profile" defaults="Profile" />}
                            icon={
                                <Icon style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon icon={faAddressCard} />
                                </Icon>
                            }
                        />
                        <Steps.Item
                            className="cursor-pointer"
                            onClick={handleSubmit(() => handleProfileDetailsDetails("Experience"))}
                            title={<Trans i18nKey="experience" defaults="Experience" />}
                            icon={
                                <Icon style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon icon={faBriefcase} />
                                </Icon>
                            }
                        />
                        <Steps.Item
                            className="cursor-pointer"
                            onClick={handleSubmit(() => handleProfileDetailsDetails("Education"))}
                            title={<Trans i18nKey="education" defaults="Education" />}
                            icon={
                                <Icon style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon icon={faGraduationCap} />
                                </Icon>
                            }
                        />
                        <Steps.Item
                            className="cursor-pointer"
                            onClick={handleSubmit(() => handleProfileDetailsDetails("Summary"))}
                            title={<Trans i18nKey="summary" defaults="Summary" />}
                            icon={
                                <Icon style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon icon={faClipboardList} />
                                </Icon>
                            }
                        />
                        
                        
                        {/* <Steps.Item
                            className="cursor-pointer"
                            onClick={handleSubmit(() => handleProfileDetailsDetails("otherDetails"))}
                            title={<Trans i18nKey="otherDetails" defaults="Other Details" />}
                            icon={
                                <Icon style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon icon={faStickyNote} />
                                </Icon>
                            }
                        /> */}
                        </> 
                        // : 
                        // <>
                        // <Steps.Item
                        //     className="cursor-pointer d-flex justify-content-start"
                        //     onClick={handleSubmit(() => handleProfileDetailsDetails("Profile"))}
                        //     title={<Trans i18nKey="profile" defaults="Profile" />}
                        //     icon={
                        //         <Icon style={{ fontSize: 20 }}>
                        //             <FontAwesomeIcon icon={faAddressCard} />
                        //         </Icon>
                        //     }
                        // />
                        // </>
                        }
                    </Steps>
                </div>
            </div>}
            <div className="">
                <div className="">
                    {targetPage == "Profile" && (
                            <Suspense fallback={<><Loading /></>}>
                                <CreateManualProfileDetailsSession
                                    register={register}
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    handleSubmit={handleSubmit}
                                    required={required}
                                    setError={setError}
                                    resumeId={resumeId}
                                    campaignID={campaignID}
                                    from = {from}
                                />
                            </Suspense>
                    )}
                    {targetPage == "Summary" && (
                            <Suspense fallback={<><Loading /></>}>
                                <CreateManualProfileSummarySession
                                    register={register}
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    handleSubmit={handleSubmit}
                                />
                            </Suspense>
                    )}
                    {targetPage == "Education" && (
                            <Suspense fallback={<><Loading /></>}>
                                <CreateManualProfileEducation
                                    register={register}
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    handleSubmit={handleSubmit}
                                    setError={setError}
                                />
                            </Suspense>
                    )}
                    {targetPage == "Experience" && (
                            <Suspense fallback={<><Loading /></>}>
                                <CreateManualProfileExperience
                                    register={register}
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    watch={watch}
                                    skip={skip}
                                    setSkip={setSkip}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    setError={setError}
                                    handleSubmit={handleSubmit}
                                    isDirty= {isDirty}
                                    isValid = {isValid}
                                />
                            </Suspense>
                    )}
                    {targetPage == "otherDetails" && (
                            <Suspense fallback={<><Loading /></>}>
                                <CreateManualProfileOtherInfo
                                    register={register}
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    handleSubmit={handleSubmit}
                                />
                            </Suspense>
                    )}
                </div>
            </div>
            {page !== "login" && page !== "guest" && page !== "referalresume" && <div className="row mt-2">
                <div  className={`col-12 d-flex  ${isSmallScreen ? 'justify-content-between' : 'justify-content-end'}`}>
                    <Button
                        className="btn btn-primary m-1"
                        onClick={handleSubmit(onPrevious)}
                        disabled={step === 0}>
                        <Trans i18nKey="previous" defaults="Previous" />
                    </Button>
                    {step !== 3 ? <Button
                        className="btn btn-primary m-1"
                        onClick={handleSubmit(onNext)}
                        disabled={step === 4}>
                            {/* || (!watch()?.createAge || !watch()?.createLegal) */}
                        <Trans i18nKey="next" defaults="Next" />
                    </Button> : 
                    <Button
                    className="btn btn-primary m-1"
                    onClick={handleSubmit(createProfileModalSubmit)}>
                    <Trans i18nKey="submit" defaults="Submit" />
                    </Button>
                    }
                </div>
            </div>}
        </>
    )


}


CreateManualProfileMain.propTypes = {
    register: PropTypes.func,
    handleSubmit: PropTypes.func,
    control: PropTypes.object,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
    errors: PropTypes.object,
    clearErrors: PropTypes.func,
    watch: PropTypes.func,
    // setCampaignOrFolderValue: PropTypes.func,
    // campaignOrFolderValue: PropTypes.func,
    required: PropTypes.bool,
    setError: PropTypes.func,
    // customTabs: PropTypes.any
};

export default CreateManualProfileMain;